"use client";
import {
  ExclamationTriangleIcon,
  FaceFrownIcon,
  MegaphoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { useEffect } from "react";

// Error components must be Client Components

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div
      data-testid="error"
      className="flex items-center h-full p-16 bg-white rounded-2xl"
    >
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className=" max-w-md text-center">
          <div className="relative">
            <h2 className="relative mb-8 z-10 font-extrabold text-9xl text-indigo-500">
              <span className="sr-only">Error</span>Oops
            </h2>
            <ExclamationTriangleIcon className="absolute -top-24 left-0 sm:-top-16 sm:-left-16 text-red-400 w-44 h-44 -rotate-12" />
            <XMarkIcon className="absolute -top-8 right-6 sm:-top-6 sm:-right-4 text-red-400 w-8 h-8 -rotate-6 stroke-[4]" />
          </div>
          <p className="text-2xl font-semibold md:text-3xl text-black">
            Sorry, an error has occurred while fetching your event.
          </p>
          <div className="relative">
            <p className="relative z-10 mt-4 mb-8 text-gray-500">
              But don&apos;t worry, you can try again or refresh the page.
              Otherwise, please{" "}
              <Link
                href={"/contact"}
                className="font-semibold text-blue-500 visited:text-purple-500"
              >
                contact support.
              </Link>
            </p>
            <FaceFrownIcon className="absolute -bottom-8 -right-2 sm:-right-[4.25rem] text-[#F9CF47] w-24 h-24 rotate-[15deg]" />
            <MegaphoneIcon className="absolute -bottom-8 left-2 sm:top-16 sm:left-16 text-red-400 w-12 h-12 rotate-12" />
          </div>

          <button
            onClick={() => reset()}
            className="mt-4 px-8 py-2 rounded-md bg-indigo-500 hover:bg-indigo-400 text-white font-semibold transition-colors duration-200 ease-in-out"
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}
